<header></header>
<!-- fixed content on page-->
<div class="fixedContent">
  <div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="5px"
  class="filters"
  >
    <mat-form-field
      matTooltip="Filter codes by their vendor"
      appearance="outline"
      class="basic"
    >
      <mat-label class="filters-label">Vendor</mat-label>
      <mat-select multiple>
        <mat-option
          *ngFor="
            let element of invoiceFilters.vendor
          "
          (onSelectionChange)="
            applyFilter(filterColumns.vendor, element, $event)
          "
        >
          {{ element }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      matTooltip="Filter codes by their code description"
      appearance="outline"
      class="basic"
    >
      <mat-label class="filters-label">Code Description</mat-label>
      <mat-select multiple>
        <mat-option
          *ngFor="
            let element of wellService.costCategoryList
              | uniqueValues: 'codeDescription'
              | orderBy: 'codeDescription'
          "
          (onSelectionChange)="
            applyFilter(
              filterColumns.codeDescription,
              element.codeDescription,
              $event
            )
          "
        >
          {{ element.codeDescription }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      matTooltip="Filter codes by their date"
      appearance="outline"
      class="basic"
    >
      <mat-label class="filters-label">Invoice Received</mat-label>
      <input
        type="text"
        matInput
        ngxDaterangepickerMd
        [showDropdowns]="true"
        [locale]="{ applyLabel: 'ok', format: 'yyyy-MM-DD' }"
        startKey="start"
        endKey="end"
        [(ngModel)]="invoiceRange"
        (ngModelChange)="
          applyFilter(filterColumns.invoiceReceived, invoiceRange, $event)
        "
        name="daterange"
        class="datepicker-calendar-icon"
      />
    </mat-form-field>
    <mat-form-field
      matTooltip="Filter codes by their type"
      appearance="outline"
      class="basic"
    >
      <mat-label class="filters-label">Cost Category</mat-label>
      <mat-select multiple>
        <mat-option
          *ngFor="
            let element of wellService.costCategoryList
              | uniqueValues: 'costCategory'
              | orderBy: 'costCategory'
          "
          (onSelectionChange)="
            applyFilter(filterColumns.costCategory, element.costCategory, $event)
          "
        >
          {{ element.costCategory }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      matTooltip="Filter codes by their invoice Status"
      appearance="outline"
      class="basic"
    >
      <mat-label class="filters-label">Invoice Status</mat-label>
      <mat-select [formControl]="invoiceStatusFilter" multiple>
        <mat-option value="pending"
          (onSelectionChange)="applyFilter(filterColumns.invoiceStatus, 'pending', $event)"
        >
          Pending
        </mat-option>
        <mat-option  value="approved"
          (onSelectionChange)="applyFilter(filterColumns.invoiceStatus, 'approved', $event)"
        >
          Approved
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="spacer"></span>
    <button class="clear-button" (click)="resetFilters()" mat-raised-button>
      Clear All
    </button>
  </div>
  <hr />
  <mat-progress-bar
    *ngIf="isLoadingFilter"
    color="primary"
    mode="query"
  ></mat-progress-bar>

  <div class="container" fxLayoutGap="10px">
    <button class="back-button" mat-button color="primary" [routerLink]="['/cost-compare']">
      <mat-icon class="back-icon">arrow_back</mat-icon> Back to Wells
    </button>
    <mat-form-field class="search-field" appearance="outline">
      <mat-label>Search in table</mat-label>
      <input
        matInput
        (keyup)="filterByValue($event.target.value)"
        placeholder="Ex. Ballymore"
        [(ngModel)]="categorySearchValue"
        #input
      />
      <button
        class="clear-button"
        mat-button
        *ngIf="categorySearchValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="categorySearchValue = ''; resetFilters()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button class="search-button" mat-raised-button color="primary">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <mat-toolbar class="table-toolbar mat-elevation-z6">
    <span class="table-title">cost categories</span>
    <button
      mat-button
      appearance="none"
      *ngIf="selection.selected.length >= 1"
      class="table-count"
    >
      {{ selection.selected.length }} wells selected<span class="material-icons">
        keyboard_arrow_down
      </span>
    </button>
    <span class="spacer"></span>
    <div fxLayoutGap="10px" style="padding-top: 17px">
      <a
        mat-button
        matTooltip="Click to view Power BI Reports"
        target="_blank"
        href={{pbiReportUrl}}
        ><img src="assets/images/powerbi.png"
      /></a>
      <mat-slide-toggle *ngIf="mgcFlag && mgcToggleFlag"
            [color]="switchColor"
            [(ngModel)]="ShowMGCDetials">
          Show MGC Details?
        </mat-slide-toggle>
      <button
        matTooltip="Click to see Last Time Data was Refreshed"
        mat-button
        (click)="openInfo()">
        <span class="material-icons"> info </span>
      </button>
    </div>
  </mat-toolbar>
</div>

<div class="table-container">
  <div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    matSort
    (matSortChange)="sortData($event)"
    class="mat-elevation-z8"
  >
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th
        matTooltip="Check here to select/unselect all"
        mat-header-cell
        *matHeaderCellDef
      >
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          style="margin-right: 10px"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Well Name Column -->
    <ng-container matColumnDef="wellName">
      <th
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="wellName"
        style="border-right: 2px solid #dbdcdd"
      >
        Well Name
      </th>
      <td
        class="td"
        style="border-right: 2px solid #dbdcdd"
        mat-cell
        *matCellDef="let COST_DATA"
      >
        {{ COST_DATA.wellName }}
      </td>
    </ng-container>

    <!-- AFE Number Column -->
    <ng-container matColumnDef="afeNumber">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header="afe">
        AFE
      </th>
      <td class="td" mat-cell *matCellDef="let COST_DATA">
        {{ COST_DATA.afeNumber }}
      </td>
    </ng-container>

    <!-- Code Description Column -->
    <ng-container matColumnDef="codeDescription">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header="codeDescription">
        Code Description
      </th>
      <td class="td" mat-cell *matCellDef="let COST_DATA">
        {{ COST_DATA.codeDescription }}
      </td>
    </ng-container>

    <!-- Primary Job Type Column -->
    <ng-container matColumnDef="primaryJobType">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header="primaryJobType">
        Primary Job Type
      </th>
      <td class="td" mat-cell *matCellDef="let COST_DATA">
        {{ COST_DATA.primaryJobType }}
      </td>
    </ng-container>

    <!-- AFE ($) Column -->
    <ng-container matColumnDef="afeCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="afeCost"
      matTooltip="AFE Dollar Amount gotten from WV">AFE($)</th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="COST_DATA.afeCost === null ? 'null-text' : ''"
      >
        {{
          COST_DATA.afeCost
            | currency: "USD":"symbol":"1.0-0"
            | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- MGC Column -->
    <ng-container matColumnDef="MGCCategories">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header disabled>
        MGC
      </th>
      <td class="td" mat-cell *matCellDef="let COST_DATA">
        <div *ngIf="COST_DATA.glDetails.length > 0; else nullBlock" class="MGC-Code-Container">
          <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
            <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item"> {{ detail.codeDescription }}</span>
          </ng-template>
          <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item">SubTotals:</span>
        </div>
        <ng-template #nullBlock>
          <span class="null-text">N/A</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- WV Field Est. ($) Column -->
    <ng-container matColumnDef="fieldEst">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="fieldEst" disabled={{mgcFlag}}
        matTooltip="The amount recorded in WellView by Wells personnel. WV is usually the first step in the cost process for a given service or product">
        WV Field Est($)
      </th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="COST_DATA.glDetails.length == 0 || COST_DATA.glDetails[0].fieldEst === null ? 'null-text' : ''"
      >
      <div *ngIf="COST_DATA.glDetails.length != 0; else nullBlock" class="MGC-Code-Container">
        <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
          <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item">
            {{
                detail.fieldEst
                  | currency: "USD":"symbol":"1.0-0"
                  | replaceNullWithText
            }}</span>
        </ng-template>
        <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item">{{ COST_DATA.FieldEstSubTotal | currency: "USD":"symbol":"1.0-0"}}</span>
      </div>
      <ng-template #nullBlock>
        <span class="null-text">N/A</span>
      </ng-template>
      </td>
    </ng-container>

    <!-- Current Invoice Column-->
    <ng-container matColumnDef="invoiceTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="invoiceTotal" disabled={{mgcFlag}}
        matTooltip={{invoiceTotalTooltip}}>
        {{invoiceTotalHeader}} Total($)
      </th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="COST_DATA.glDetails.length == 0 || COST_DATA.glDetails[0].invoiceTotal === null ? 'null-text' : ''"
      >
      <div *ngIf="COST_DATA.glDetails.length != 0; else nullBlock" class="MGC-Code-Container">
        <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
          <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item">
            {{
                detail.invoiceTotal
                  | currency: "USD":"symbol":"1.0-0"
                  | replaceNullWithText
            }}</span>
        </ng-template>
        <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item">{{ COST_DATA.InvoiceSubTotal | currency: "USD":"symbol":"1.0-0"}}</span>
      </div>
      <ng-template #nullBlock>
        <span class="null-text">N/A</span>
      </ng-template>
      </td>
    </ng-container>

    <!-- SAP ($) Column -->
    <ng-container matColumnDef="sapCost">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="sapCost" disabled={{mgcFlag}}
        matTooltip="The amount recorded in SAP based on invoices approved, and the final amount that has “hit the books”. Usually the last step in the cost process for a given service or product">
        SAP($)
      </th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="COST_DATA.glDetails.length == 0 || COST_DATA.glDetails[0].sapCost === null ? 'null-text' : ''"
      >
      <div *ngIf="COST_DATA.glDetails.length != 0; else nullBlock" class="MGC-Code-Container">
        <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
          <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item">
            {{
            detail.sapCost === null
              ? "N/A"
              : (detail.sapCost | currency: "USD":"symbol":"1.0-0")
            }}</span>
        </ng-template>
        <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item">{{ COST_DATA.SapSubTotal | currency: "USD":"symbol":"1.0-0"}}</span>
      </div>
      <ng-template #nullBlock>
        <span class="null-text">N/A</span>
      </ng-template>


      </td>
    </ng-container>

    <!-- WV Field Est./AFE($) Column -->
    <ng-container matColumnDef="calculateWellviewDiffCost">
      <th
        matTooltip="Wellview Field Est. vs AFE differrence in dollars"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="calculateWellviewDiffCost"
      >
        WV Field Est - AFE($)
      </th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="COST_DATA.calculateWellviewDiffCost === null ? 'null-text' : ''"
      >
        {{
          COST_DATA.calculateWellviewDiffCost
            | currency: "USD":"symbol":"1.0-0"
            | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./AFE(%) Column -->
    <ng-container matColumnDef="calculateWellviewDiffPercent">
      <th
        matTooltip="Wellview Field Est. vs AFE difference in percent"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="calculateWellviewDiffPercent"
      >
        WV Field Est vs AFE(%)
      </th>
      <td
        class="td-2"
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="
          COST_DATA.calculateWellviewDiffPercent > 3 ||
          COST_DATA.calculateWellviewDiffPercent <= -3
            ? 'negative'
            : COST_DATA.calculateWellviewDiffPercent === null
            ? 'null-text'
            : 'positive'
        "
      >
        {{
          COST_DATA.calculateWellviewDiffPercent === null
            ? "N/A"
            : (COST_DATA.calculateWellviewDiffPercent / 100 | percent: "1.2-2")
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./SAP($) Column -->
    <ng-container matColumnDef="calculateSapDiffCost">
      <th
        matTooltip="Wellview Field Est. vs SAP differrence in dollars"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="calculateSapDiffCost"
        disabled={{mgcFlag}}
      >
        WV Field Est - SAP($)
      </th>
      <td
        mat-cell
        *matCellDef="let COST_DATA"

      >
      <div *ngIf="COST_DATA.glDetails.length > 0; else nullBlock" class="MGC-Code-Container" >
        <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
          <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item" [ngClass]="detail.length == 0 || detail.calculateSapDiffCost === null ? 'null-text' : ''">
            {{
              detail == undefined ? "N/A" : detail.calculateSapDiffCost
                | currency: "USD":"symbol":"1.0-0"
                | replaceNullWithText
            }}</span>
        </ng-template>
        <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item" [ngClass]="COST_DATA.length == 0 || COST_DATA.calculateSapDiffCostTotal === null ? 'null-text' : ''">
          {{ COST_DATA.calculateSapDiffCostTotal | currency: "USD":"symbol":"1.0-0" | replaceNullWithText}}
        </span>
      </div>

      <ng-template #nullBlock>
        <span class="null-text">N/A</span>
      </ng-template>

      </td>
    </ng-container>

    <!-- WV Field Est./SAP(%) Column -->
    <ng-container matColumnDef="calculateSapDiffPercent">
      <th
        matTooltip="Wellview Field Est. vs SAP differrence in percent"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="calculateSapDiffPercent"
        disabled={{mgcFlag}}
      >
        WV Field Est vs SAP(%)
      </th>
      <td
        class="td-2"
        mat-cell
        *matCellDef="let COST_DATA"
        [ngClass]="determineColorClass(COST_DATA, 'calculateSapDiffPercent')"
      >
      <div *ngIf="COST_DATA.glDetails.length > 0; else nullBlock" class="MGC-Code-Container">
        <ng-template ngFor let-detail [ngForOf]="COST_DATA.glDetails">
          <span *ngIf="ShowMGCDetials || COST_DATA.glDetails.length == 1" class="MGC-Code-Item" [ngClass]="determineMGCColorClass(detail, 'calculateSapDiffPercent')">
            {{
              detail == undefined ? "N/A" : detail.calculateSapDiffPercent === null
                ? "N/A"
                : (detail.calculateSapDiffPercent / 100 | percent: "1.2-2")
            }}</span>
        </ng-template>
        <span *ngIf="COST_DATA.glDetails.length > 1" class="MGC-Code-Item" [ngClass]="determineMGCColorClass(COST_DATA, 'calculateSapDiffPercentTotal')">
          {{ COST_DATA.calculateSapDiffPercentTotal / 100 | percent: "1.2-2"}}
        </span>
      </div>

      <ng-template #nullBlock>
        <span class="null-text">N/A</span>
      </ng-template>

      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let expandedElement"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="cost-detail"
          [@detailExpand]="
            expandedElement == expandedCost ? 'expanded' : 'collapsed'
          "
        >
          <mat-tab-group>
            <mat-tab *ngFor="let vendorTab of expandedElement.glDetails; index as i" label="{{vendorTab.codeDescription + ' - Vendors'}}">
              <div class="equal-height-container">
                <div class="first">
                  <div>
                    <div class="cost-description-value-well-name"></div>
                  </div>
                </div>

                <div class="vendorContainer">
                  <!-- WellView Vendor Column -->
                  <div class="vendorColumn">
                    <h2>WellView</h2>
                    <p>Grand Total</p>
                    <h1 class="cost-vendor-total">
                      {{ getTotalWVVendor(i) | currency }}
                    </h1>
                    <table class="WVVendorTable" style="overflow: hidden">
                      <tr>
                        <th>vendor</th>
                        <th>WV Field Est ($)</th>
                      </tr>
                      <tr *ngFor="let row of vendorTab.wellviewVendors | orderBy: 'vendorDescription'">
                        <td color="primary">
                          {{ row.vendorDescription }}
                        </td>
                        <td>
                          {{ row.cost | currency }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- invoice Vendor Column -->
                  <div class="vendorColumn">
                    <h2>{{invoiceTotalHeader}} Vendors</h2>
                    <p>Grand Total</p>
                    <h1 class="cost-vendor-total">
                      {{ getTotalInvoiceVendor(i) | currency }}
                    </h1>
                    <table class="SAPVendorTable" style="overflow: hidden">
                      <tr style="text-align: end">
                        <th>vendor</th>
                        <th>suppler ID</th>
                        <th>Total Pending Invoice Cost</th>
                      </tr>
                      <tr *ngFor="let row of vendorTab.invoiceVendors | orderBy: 'vendor'">
                        <mat-checkbox
                          style="margin-right: 10px"
                          color="primary"
                          (click)="$event.stopPropagation()"
                          (change)="$event ? updateSelectedVendors(row) : null"
                          [checked]="vendorSelection.isSelected(row)"
                          [aria-label]="row.vendor"
                        >
                          {{ row.vendor }}
                        </mat-checkbox>
                        <td>{{ row.supplierId }}</td>
                        <td>{{ row.calculatedSap | currency }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- SAP Vendor Column -->
                  <div class="vendorColumn">
                    <h2>SAP Actuals</h2>
                    <p>Grand Total</p>
                    <h1 class="cost-vendor-total">
                      {{ getTotalSapVendor(i) | currency }}
                    </h1>
                    <table class="SAPVendorTable" style="overflow: hidden">
                      <tr style="text-align: end">
                        <th>vendor</th>
                        <th>suppler ID</th>
                        <th>CA Calculated SAP ($)</th>
                      </tr>
                      <tr *ngFor="let row of vendorTab.sapVendors | orderBy: 'vendor'">
                        <td>{{ row.vendor }}</td>
                        <td>{{ row.supplierId }}</td>
                        <td>
                          {{ row.calculatedSap | currency }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Invoices">
              <div class="equal-height-container">
                <div class="first">
                  <div>
                    <div class="cost-description-value-well-name">
                      A possible quick description of the invoices and general
                      list of items it entails.
                    </div>
                  </div>
                </div>
                <div class="second">
                  <div class="second-a">
                    <h2>{{ getSelectedVendorNames() }}</h2>
                  </div>
                  <div class="second-a">
                    <div class="total-row">
                      <div class="total-column">
                        <p>Total of Approved Invoices</p>
                        <h1 class="cost-vendor-total">
                          {{ getTotalSapInvoice() | currency }}
                        </h1>
                      </div>
                      <div class="total-column">
                        <p>Total of Pending Invoices</p>
                        <h1 class="cost-vendor-total">
                          {{ getTotalAribaInvoice() | currency }}
                        </h1>
                      </div>
                      <div class="total-column">
                        <p>Grand Total Invoices</p>
                        <h1 class="cost-vendor-total">
                          {{ getTotalGrandInvoice() | currency }}
                        </h1>
                      </div>
                      <div class="container" fxLayoutGap="10px">
                        <mat-form-field class="search-field" appearance="outline">
                          <mat-label>Search for Invoices</mat-label>
                          <input
                            matInput
                            (keyup)="filterInvoiceByValue($event.target.value)"
                            placeholder="Ex. XML24829846"
                            [(ngModel)]="invoiceSearchValue"
                            #input
                          />
                          <button
                            class="clear-button"
                            mat-button
                            *ngIf="invoiceSearchValue"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="invoiceSearchValue = ''; resetInvoiceFilters()"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                        <button class="search-button" mat-raised-button color="primary">
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="second-a">
                    <div>
                      <table matSort (matSortChange)="sortInvoices($event)" id="invoiceTable" style="overflow: hidden">
                        <tr *ngIf="filteredInvoices != null && filteredInvoices.length > 300" class="invoice-warning-text">Note: Due to invoice volume and data limits, we are only showing the first 300 records</tr>
                        <tr *ngIf="filteredInvoices == null || filteredInvoices.length == 0" class="invoice-warning-text">No invoices to show</tr>
                        <tr>
                          <th mat-sort-header="aribaDocID">Ariba doc ID</th>
                          <th mat-sort-header="contractID">Contract ID</th>
                          <th mat-sort-header="SupplierID">Supplier ID</th>
                          <th mat-sort-header="invoiceReceived">Invoice Received</th>
                          <th mat-sort-header="invoiceApproved">Invoice Approved</th>
                          <th mat-sort-header="serviceDate">Service Date</th>
                          <th mat-sort-header="status">Status</th>
                          <th mat-sort-header="description">Description</th>
                          <th mat-sort-header="sapDescription">SAP Description</th>
                          <th mat-sort-header="itemNumber">Item Number</th>
                          <th mat-sort-header="unitOfMeasure">Unit of Measure</th>
                          <th mat-sort-header="unitPrice">Unit Price</th>
                          <th mat-sort-header="quantity">Quantity</th>
                          <th mat-sort-header="amountAfterDiscount">Amount after Discount</th>
                          <th mat-sort-header="DSO">DSO</th>
                          <th mat-sort-header="invoiceDocument" disabled>Invoice Document</th>
                        </tr>
                        <tr *ngFor="let row of filteredInvoices?.slice(0, 300)">
                          <td
                            [ngClass]="
                              row.statusString == 'Approved'
                                ? 'approved-border'
                                : 'pending-border'
                            "
                          >
                            {{ row.aribaDocId }}
                          </td>
                          <td>{{ row.contractId }}</td>
                          <td>{{ row.SupplierID }}</td>
                          <td>{{ (row.invoiceReceived == "N/A") ? ( row.invoiceReceived ) : (row.invoiceReceived | date: "shortDate") }}</td>
                          <td>{{ row.approvedDate == "N/A" ? row.approvedDate : (row.approvedDate | date: "shortDate" ) }}</td>
                          <td>{{ (row.EndDate == "N/A") ? ( row.EndDate ) : (row.EndDate | date: "shortDate") }}</td>

                          <td
                            [ngClass]="
                              row.statusString == 'Approved'
                                ? 'approved-fill'
                                : 'pending-fill'
                            "
                          >
                            {{ row.statusString }}
                          </td>
                          <td>{{ row.description }}</td>
                          <td>{{ row.sapDescription }}</td>
                          <td>{{ row.partKey }}</td>
                          <td>{{ row.unitMeasure }}</td>
                          <td>{{ row.unitPrice | currency }}</td>
                          <td>{{ row.Quantity }}</td>
                          <td>{{ row.discountAmount | currency }}</td>
                          <td [ngClass]="
                            row.DSO == null
                              ? ''
                              : row.DSO < 30
                              ? 'approved-fill'
                              : row.DSO > 90
                              ? 'negative'
                              : 'warning'"
                          >{{ row.DSO }}</td>
                          <td>
                          <a mat-icon-button href="{{row.aribaLink}}" target="_blank" ng-if="row.aribaLink">
                            <mat-icon>open_in_new</mat-icon>
                          </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let costElement; columns: displayedColumns"
      class="cost-row"
      (click)="expandRowClick(costElement)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
