export interface ICostCategory {
    wellId: string;
    wellName: string;
    afeNumber: string;
    afeCost: number;
    primaryJobType: string;
    codeDescription: string;
    calculateWellviewDiffCost?: number;
    calculateWellviewDiffPercent?: number;
    wellSummary?: string;
    costCategory: string;
    glDetails: GLDetails[];

}

export class CostCategory implements ICostCategory {
    MixCategoryFlag: boolean;
    MixCategoryParent: string;
    FieldEstSubTotal: number;
    SapSubTotal: number;
    calculateWellviewDiffCost: number;
    calculateWellviewDiffPercent: number;
    calculateSapDiffCostTotal: number;
    calculateSapDiffPercentTotal: number;
    HasPendingInvoices: boolean;
    HasApprovedInvoices: boolean;
    constructor(public wellName: string,
        public primaryJobType: string,
        public afeNumber: string,
        public wellSummary: string,
        public wellId: string,
        public costCategory: string,
        public codeDescription: string,
        public afeCost: number,
        public glDetails: GLDetails[]) {
    }
}

export class GLDetails {
    sapCost?: number;
    fieldEst?: number;
    calculateSapDiffCost?: number;
    calculateSapDiffPercent?: number;
    codeDescription: string;
    sapVendors: sapVendor[];
    wellviewVendors: wellviewVendor[];
    TotalSapVendor: number;
    TotalWellviewVendor: number;
    invoiceVendors: sapVendor[];

    get VendorLabel(): string {
        return this.codeDescription + " - Vendors";
    }
}

export class sapVendor {
    vendor: string;
    supplierId: string;
    calculatedSap: string;
    TotalAribaInvoice: number;
    TotalSapInvoice: number;
    TotalGrandInvoice: number;
    description: string;
    invoices: invoice[];
    filteredInvoices: invoice[];
    jobType: string;
}

export class wellviewVendor {
    vendorDescription: string;
    cost: number;
}

export class invoice {
    invoiceReceived: string;
    aribaDocId: string;
    contractId: string;
    discountAmount: string;
    description: string;
    partKey: string;
    sapDescription: string;
    Quantity: string;
    unitMeasure: string;
    unitPrice: string;
    statusString: string;
    approvedDate: string;
    EndDate: string;
    category: string;
    aribaLink: string;
    DSO: string;
    SupplierID: string;
}

export class invoiceFilter {
    codeDescription: string[];
    vendor: string[];
    invoiceReceived: Date[];
    costCategory: string[];
    hasPendingInvoices: boolean;
    hasApprovedInvoices: boolean;
}

export class costCategoryPageContainer{
    previousPage: string;
    nextPage: string;
    costCategories: CostCategory[];
}

export enum FilterColumns {
    operator = 'operator',
    area = 'area',
    fieldName = 'fieldName',
    sapWellName = 'sapWellName',
    rigName = 'rigName',
    primaryJobType = 'primaryJobType',
    formation = 'formation',
    jobEndYear = 'jobEndYear',
    favorite = 'favorite',
    devArea = 'devAreaCode',
    padName = 'padName'
  }


export enum InvoiceFilterColumns {
    codeDescription = 'codeDescription',
    vendor = 'vendor',
    invoiceReceived = 'invoiceReceived',
    costCategory = 'costCategory',
    invoiceStatus = 'invoiceStatus'
  }

  export class SelectedWell{
    wellID: string;
    afeNumber: string;
    jobStart: string;
}

export class SelectedVendor{
    SupplierID: string;
    description: string;
    afeNumber: string;
    wellID: string;
    jobType: string;
}

export class DataRefresh {
    Datasource: string;
    LastRefresh: Date;
}

export class UserFavorite {
    CAI: string;
    WellId: string;
    AfeNumber: string;
    JobStart: string;
}

export class WellDetailsColumn {
    Title: string;
    IWellAttributeName: string;

    constructor(title: string, wellAttributeName: string)
  {
    this.Title = title;
    this.IWellAttributeName = wellAttributeName;
  }
}
