import { Component, OnInit, ViewChild } from '@angular/core';
import { IWells } from '../../interfaces/wells.interface';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SettingsTableModalComponent } from '../settings-table-modal/settings-table-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WellsService } from 'src/app/services/wells.service';
import { MatSort, MatSortable } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { DataInfoModalComponent } from '../data-info-modal/data-info-modal.component';
import { DataRefresh } from 'src/app/interfaces/cost-category';
import { environment } from 'src/environments/environment';
import { BU } from 'src/app/interfaces/business-unit';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CalAngularService } from '@cvx/cal-angular';

@Component({
  selector: 'compare-wells',
  templateUrl: './compare-wells.component.html',
  styleUrls: ['./compare-wells.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class CompareWellsComponent implements OnInit {
  value = '';
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['select', 'wellviewWellName', 'afeNumber', 'primaryJobType', 'afeCost', 'fieldEst', 'sapCost', 'calculateWellviewDiffCost', 'calculateWellviewDiffPercent', 'calculateSapDiffCost', 'calculateSapDiffPercent'];
  dataSource;
  well;
  selection = new SelectionModel<IWells>(true, []);
  expandedCost: IWells | null;
  dataRefreshInfo: DataRefresh[] = [];
  selectedBU: BU;
  wells: IWells[];
  pbiReportUrl: string;

  constructor(
    public dialog: MatDialog, 
    public wellService: WellsService, 
    public buService: BusinessUnitService, 
    private datePipe: DatePipe,
    private myMonitoringService: MyMonitoringService,
    private authService: CalAngularService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  openSettings() {
    this.dialog.open(SettingsTableModalComponent, {
      width: '860px',
      height: '300px'
    });
  }

  openInfo() {
    let refreshDataMapped: { Datasource: string; LastRefresh: string }[] = [];
    this.dataRefreshInfo.forEach(item => {
      let element = { Datasource: item.Datasource, LastRefresh: this.datePipe.transform(item.LastRefresh, 'yyyy-MM-dd') };
      refreshDataMapped.push(element);
    })
    this.dialog.open(DataInfoModalComponent, {
      width: '860px',
      height: '300px',
      data: { dataRefresh: refreshDataMapped }
    })
  }

  ngOnInit() {
    this.selectedBU = this.buService.getSelectedBU();
    this.logTelemtry();
    if(this.selectedBU==BU.GOMBU)
    {
       this.pbiReportUrl = environment.pbiReportGOM;
    }
    else if(this.selectedBU==BU.MCBU)
    {
    this.pbiReportUrl = environment.pbiReportMCBU;
    }
    else if(this.selectedBU==BU.RBU)
    {
    this.pbiReportUrl = environment.pbiReportRBU;
    }
    else if(this.selectedBU==BU.CEMREC)
    {
    this.pbiReportUrl = environment.pbiReportCEMREC;
    }
    else if(this.selectedBU==BU.LABU)
    {
    this.pbiReportUrl = environment.pbiReportCEMREC;
    }
    this.wells = this.wellService.selectedWells;
    this.dataRefreshInfo = this.wellService.dataRefresh;
    this.dataSource = new MatTableDataSource(this.wells);
  }

  logTelemtry() {
    this.myMonitoringService.logPageView("Compare_Wells_Page");
    for(let well of this.wellService.selectedWells)
    {
      let properties: {[k: string]: any} = {};
      properties.WellID = well.wellId;
      properties.User = this.authService.cvxClaimsPrincipal.email;
      properties.BU = BU[this.selectedBU];
      properties.jobTitle = this.authService.cvxClaimsPrincipal.jobTitle;
      this.myMonitoringService.logEvent("Compare_Wells_Open_Event", properties);
    }
  }

  expandRowClick(costElement: IWells) {
    this.expandedCost = this.expandedCost === costElement ? null : costElement;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: IWells): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.wellviewWellName + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  getTotalAfe() {
    return this.wells.map(t => t.afeCost).reduce((acc, value) => acc + value, 0);
  }
  getTotalfieldEst() {
    return this.wells.map(t => t.fieldEst).reduce((acc, value) => acc + value, 0);
  }
  getTotalSapCost() {
    return this.wells.map(t => t.sapCost).reduce((acc, value) => acc + value, 0);
  }
  getTotalWvDiffCost() {
    return this.getTotalfieldEst() - this.getTotalAfe();
  }
  getTotalWvDiffPercent() {
    return this.getTotalWvDiffCost()/this.getTotalAfe();
  }
  getTotalSAPDiffCost() {
    return this.getTotalfieldEst() - this.getTotalSapCost();
  }
  getTotalSAPDiffPercent() {
    return this.getTotalSAPDiffCost()/this.getTotalSapCost();
  }
  sortDataSource(id: string, start: string) {
    this.dataSource.sort.sort(<MatSortable>({ id: id, start: start }));
  }

}
