import { BU } from 'src/app/interfaces/business-unit';
import { environment } from 'src/environments/environment';
import { Component, OnInit, AfterContentInit, ViewChild, AfterViewInit, ChangeDetectorRef, QueryList, ViewChildren } from '@angular/core';
import { IWells, IWellsPageContainer } from '../../interfaces/wells.interface';
import { MatSelect } from '@angular/material/select';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WellsService } from 'src/app/services/wells.service';
import { WellError } from 'src/app/services/wellError';
import { Router } from '@angular/router';
import { DataRefresh, FilterColumns, SelectedWell, UserFavorite, WellDetailsColumn } from '../../interfaces/cost-category';
import { DataInfoModalComponent } from '../data-info-modal/data-info-modal.component';
import { DatePipe } from '@angular/common';
import { UniqueValuesPipe } from 'src/app/pipes/unique-values.pipe';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { UntypedFormControl } from '@angular/forms';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { CalAngularService } from '@cvx/cal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyMonitoringService } from 'src/app/services/logging.service';

class DropdownOption {
  id: number;
  value: string;
}

class Filter {
  form: UntypedFormControl;
  options: DropdownOption[];
  key: string;
  title: string;

  constructor(form, options, key, title)
  {
    this.form = form;
    this.options = options;
    this.key = key;
    this.title = title;
  }
}


@Component({
  selector: 'cost-compare',
  templateUrl: './cost-compare.component.html',
  styleUrls: ['./cost-compare.component.scss'],
  providers: [ OrderByPipe, UniqueValuesPipe ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class CostCompareComponent implements OnInit, AfterViewInit {
  sapWellName: string;
  area: string;
  issuesCount: number = 0;
  nullCount: number = 0;

  pbiReportUrl: string;
  value = '';
  switchColor: ThemePalette = 'primary';
  favoriteFlag: boolean = false;
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['select', 'wellviewWellName', 'afeNumber', 'primaryJobType', 'afeCost', 'fieldEst', 'sapCost', 'calculateWellviewDiffCost', 'calculateWellviewDiffPercent', 'calculateSapDiffCost', 'calculateSapDiffPercent'];
  dataSource: MatTableDataSource<IWells>;
  dataRefreshInfo: DataRefresh[] = [];
  well;
  selection = new SelectionModel<IWells>(true, []);
  expandedCost: IWells | null;
  selectedOption: string;
  wells: IWells[];
  isLoading = true
  isLoadingFilter = false;
  filterValues: any = {};
  filterColumns = FilterColumns;
  AreaForm = new UntypedFormControl();
  FieldNameForm = new UntypedFormControl();
  WellForm = new UntypedFormControl();
  RigForm = new UntypedFormControl();
  JobTypeForm = new UntypedFormControl();
  FormationForm = new UntypedFormControl();
  JobEndDateForm = new UntypedFormControl();
  PadNameForm = new UntypedFormControl();
  DevAreaForm = new UntypedFormControl();
  areaFilters: DropdownOption[] = [];
  fieldNameFilters: DropdownOption[] = [];
  wellFilters: DropdownOption[] = [];
  rigFilters: DropdownOption[] = [];
  jobTypeFilters: DropdownOption[] = [];
  formationFilters: DropdownOption[] = [];
  jobEndDateFilters: DropdownOption[] = [];
  padNameFilters: DropdownOption[] = [];
  devAreaFilters: DropdownOption[] = [];

  allFilters: Filter[] = [];
  BuSpecificFilterArray: Filter[] = [];
  filterMap: Map<string, Filter> = new Map<string, Filter>();

  BuSpecificDetailColumns: WellDetailsColumn [] = [];
  selectedBU: BU;
  
  set data(value: IWells[]) {
    this.wellService.selectedWells = this.selection.selected;
  }
  constructor(public dialog: MatDialog, public wellService: WellsService, public buService: BusinessUnitService, private router: Router, private cd: ChangeDetectorRef,
    private datePipe: DatePipe, private uniqueValuePipe: UniqueValuesPipe, private orderByPipe: OrderByPipe, private authService: CalAngularService,
    public _snackBar: MatSnackBar, private myMonitoringService: MyMonitoringService) 
    {}

  openInfo() {
    let refreshDataMapped: { Datasource: string; LastRefresh: string }[] = [];
    this.dataRefreshInfo.forEach(item => {
      let element = { Datasource: item.Datasource, LastRefresh: this.datePipe.transform(item.LastRefresh, 'yyyy-MM-dd') };
      refreshDataMapped.push(element);
    })
    this.dialog.open(DataInfoModalComponent, {
      width: '860px',
      height: '300px',
      data: { dataRefresh: refreshDataMapped }
    })
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatSelect) select: QueryList<MatSelect>;

  ngOnInit() {
    this.initializeFilterMap();
    this.BuSpecificDetailColumns = this.buService.getWellDetailColumns();
    this.pbiReportUrl = this.buService.getPowerBILink();
    this.selectedBU = this.buService.getSelectedBU();
    this.logTelemtry();
  }

  ngAfterViewInit() {
    this.wellService.getDataRefresh()
      .subscribe((refreshes: DataRefresh[]) => {
        this.dataRefreshInfo = refreshes;
      });
    this.wellService.getWells()
      .subscribe((x: IWellsPageContainer) => {
        this.wells = x.wellCosts;
        this.wellService.getFavorites().subscribe((favorites: UserFavorite[]) => {
          for(let i = 0; i < favorites.length; i++)
          {
            let favorite = favorites[i];
            let matchedWellCost = this.wells.find(x => x.wellId == favorite.WellId && x.jobStart == favorite.JobStart && x.afeNumber == favorite.AfeNumber);
            if(matchedWellCost != undefined)
              matchedWellCost.favorite = true;
          }
        });
        this.dataSource = new MatTableDataSource(this.wells);
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setFilterOptions(this.wells, true);
        this.isLoading = false;
        this.issuesCount = this.getIssuesCount();
        this.nullCount = this.getNullCount();
        this.filterValues = this.wellService.selectedFilters;
        this.restorePreviousFilters();
        this.dataSource.filter = JSON.stringify(this.filterValues);
        this.cd.detectChanges();
      })
  }

  logTelemtry() {
    this.myMonitoringService.logPageView("Cost_Compare_Page");
    let properties: {[k: string]: any} = {};
    properties.User = this.authService.cvxClaimsPrincipal.email;
    properties.BU = BU[this.selectedBU];
    properties.jobTitle = this.authService.cvxClaimsPrincipal.jobTitle;
    this.myMonitoringService.logEvent("Cost_Compare_Open_Event", properties);
  }

  expandRowClick(costElement: IWells) {
    this.expandedCost = this.expandedCost === costElement ? null : costElement;
  }

  applyFilter(column, filterValue: string, event) {
    if (event.source.selected || event.source.checked) {
      if(column == this.filterColumns.favorite)
      {
        filterValue = '1';
      }
      this.filterValues[column] = (this.filterValues[column] != '' && this.filterValues[column] != null && this.filterValues[column] != undefined)
      ? (this.filterValues[column] + ',' + filterValue.trim().toLowerCase())
      : filterValue.trim().toLowerCase(); // Remove whitespace
    } else {
      if(column == this.filterColumns.favorite)
      {
        filterValue = '1';
      }
      this.filterValues[column] = this.removeValue(this.filterValues[column], filterValue.trim().toLowerCase(), ',');
      if (!this.filterValues[column]) {
        delete this.filterValues[column];
      }
    }
    this.dataSource.filter = JSON.stringify(this.filterValues);
    this.setFilterOptions(this.dataSource.filteredData, false);
  }

  removeValue(list:string, value, separator) {
    separator = separator || ",";
    if (list) {
      var values = list.split(separator);
      for (var i = 0; i < values.length; i++) {
        if (values[i] == value) {
          values = values.filter(x=> x != value);
          return values.join(separator);
        }
      }
    }
    return list;
  }

  filterByValue(searchTerm) {
    let filter = this.dataSource.filter;
    let filterObj = JSON.parse(filter);
    filterObj.custom = searchTerm;
    this.dataSource.filter = JSON.stringify(filterObj);
    this.setFilterOptions(this.dataSource.filteredData, false);
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      // Get all active filters
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col] !== null && searchTerms[col] !== undefined) {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        let foundInColumn: boolean[] = [];
        if (isFilterSet) {
          // For every type of filter, find if at least one criteria is true.  Custom column checks against whole data object
          for (const col in searchTerms) {
            let found = false;
            if(col == "custom")
            {
              let dataJson = JSON.stringify(data).toLowerCase();
              found = dataJson.indexOf(searchTerms[col].toLowerCase()) !== -1;
            }
            else
            {
              searchTerms[col].trim().toLowerCase().split(',').forEach(word => {
                if (data[col] && data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                  found = true;
                }
              });
            }

            foundInColumn.push(found);
          }
          // Return true if every column returned true, else false
          return foundInColumn.reduce((acc, cur) => acc && cur);
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction;
  }

  filterIssues() {
    this.isLoadingFilter = true
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.wells.filter((df => df.calculateSapDiffPercent >= 3 || df.calculateSapDiffPercent <= -3)));
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingFilter = false
    }, 1)
  }
  filterNulls() {
    this.isLoadingFilter = true
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.wells.filter((df => df.fieldEst === null || df.afeCost === null)));
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingFilter = false
    }, 1)
  }

  sortDataSource(id: string, start: string) {
    this.dataSource.sort.sort(<MatSortable>({ id: id, start: start }));
  }

  resetFilters() {
    this.isLoadingFilter = true;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.wells);
      this.filterValues = {};
      this.dataSource.filter = JSON.stringify(this.filterValues);
      this.select.toArray().forEach((s: MatSelect) => s.options.forEach((item: MatOption) => item.deselect()));
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.setFilterOptions(this.wells, true);
      this.favoriteFlag = false;
      this.isLoadingFilter = false;
      this.value = '';
    }, 1)
  }

  getIssuesCount() {
    const issuesCountNumber = this.wells.filter((df => df.calculateSapDiffPercent >= 3 || df.calculateSapDiffPercent <= -3))
    return issuesCountNumber.length
  }
  getNullCount() {
    const nullCountNumber = this.wells.filter((df => df.fieldEst === null || df.afeCost === null))
    return nullCountNumber.length
  }

  setFilterOptions(dataSource: IWells[], initialize: boolean) {

    for(let filterKey of this.filterMap.keys())
    {
      if(!this.filterValues.hasOwnProperty(filterKey) || initialize)
      {
        let filterObj = this.filterMap.get(filterKey);
        filterObj.options = [];
        let options = this.orderByPipe.transform(this.uniqueValuePipe.transform(dataSource, filterKey), filterKey).map(x => x[filterKey]);
        options.forEach((option, i) => filterObj.options.push({ id: i, value: option}));
      }
    }
  }

  restorePreviousFilters() {
    for(let filter of this.BuSpecificFilterArray)
    {
      this.restoreFilter(filter);
    }
  }

  restoreFilter(filter: Filter)
  {
    if (this.filterValues.hasOwnProperty(filter.key)) {
      let separator = ",";
      let list: string = this.filterValues[filter.key];
      let selectedOptions: DropdownOption[] = [];
      if (list != undefined) {
        var values = list.split(separator);
        values.forEach(value => {
          let selectedOption = filter.options.findIndex(x => x.value != null && x.value.toLowerCase() == value);
          if (selectedOption != -1) {
            selectedOptions.push(filter.options[selectedOption]);
          }
        })
      }
      filter.form.setValue(selectedOptions);
    }
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: IWells): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.wellviewWellName + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection == null || this.selection.selected == null ? 0 : this.selection.selected.length;
    const numRows = this.dataSource == null || this.dataSource.filteredData == null ? 0 : this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  async routeToCompareWells(event: Event) {
    this.wellService.selectedFilters = this.filterValues;
    this.wellService.selectedWells = this.selection.selected;
    switch (this.selectedOption) {
      case 'compare-wells':
        this.isLoading = true;
        this.router.navigate(['/compare-wells']);
        break;
      case 'cost-categories':
        this.wellService.selectedWellApiData = [];
        this.selection.selected.forEach(item => {
          let sWell = new SelectedWell();
          sWell.wellID = item.wellId;
          sWell.afeNumber = item.afeNumber;
          sWell.jobStart = item.jobStart;
          this.wellService.selectedWellApiData.push(sWell);
        });
        this.router.navigate(['/cost-categories']);
        break;
      case 'add-to-favorites':
        this.isLoading = true;
        let favorites = this.mapSelectedWellsToFavorites();
        this.wellService.addToFavorites(favorites).subscribe(() => 
        {
          this.isLoading = false;
          this._snackBar.open('Bookmarks Successfully Added', 'Dismiss', {
            duration: 3000
          });
        }, error => {
          this.isLoading = false;
          this._snackBar.open('Error adding favorite: ' + error, 'Dismiss', {
            duration: 5000
          })});
        break;
    }
  }

  mapSelectedWellsToFavorites(): UserFavorite[]
  {
    let favorites: UserFavorite[] = [];
    let unfavoritedSelections = this.selection.selected.filter(x => !x.favorite);
    for(let well of unfavoritedSelections)
    {
      well.favorite = true;
      let favorite = new UserFavorite();
      favorite.AfeNumber = well.afeNumber;
      favorite.WellId = well.wellId;
      favorite.JobStart = well.jobStart;
      favorite.CAI = this.authService.cvxClaimsPrincipal.cai;
      favorites.push(favorite);
    }
    return favorites;
  }

  removeFavorite(well: IWells)
  {
    let favorite: UserFavorite = new UserFavorite();
    favorite.AfeNumber = well.afeNumber;
    favorite.WellId = well.wellId;
    favorite.JobStart = well.jobStart;
    favorite.CAI = this.authService.cvxClaimsPrincipal.cai;
    well.favorite = false;
    this.isLoading = true;
    this.wellService.removeFavorites([favorite]).subscribe(() =>
    {
      this.isLoading = false;
      this._snackBar.open('Favorites Successfully Removed', 'Dismiss', {
        duration: 3000
      });
    }, error => {
      this.isLoading = false;
      this._snackBar.open('Error removing favorite: ' + error, 'Dismiss', {
        duration: 5000
      });
    })
  }

  initializeFilterMap()
  {
    this.allFilters.push(new Filter(this.AreaForm, this.areaFilters, FilterColumns.area, "Areas"));
    this.allFilters.push(new Filter(this.FieldNameForm, this.fieldNameFilters, FilterColumns.fieldName, "Fields"));
    this.allFilters.push(new Filter(this.WellForm, this.wellFilters, FilterColumns.sapWellName, "Wells"));
    this.allFilters.push(new Filter(this.RigForm, this.rigFilters, FilterColumns.rigName, "Rigs"));
    this.allFilters.push(new Filter(this.JobTypeForm, this.jobTypeFilters, FilterColumns.primaryJobType, "Job Type"));
    this.allFilters.push(new Filter(this.FormationForm, this.formationFilters, FilterColumns.formation, "Formation"));
    this.allFilters.push(new Filter(this.JobEndDateForm, this.jobEndDateFilters, FilterColumns.jobEndYear, "Job End Date"));
    this.allFilters.push(new Filter(this.PadNameForm, this.padNameFilters, FilterColumns.padName, "Pad Name"));
    this.allFilters.push(new Filter(this.DevAreaForm, this.devAreaFilters, FilterColumns.devArea, "Dev Area"));
console.log("allfilters "+this.allFilters);
    let wellFilters = this.buService.getWellFilters();
    console.log(wellFilters);
    for(let filter of wellFilters)
    {
      let matchedFilter = this.allFilters.find(x => x.key === filter);
      console.log(matchedFilter.options);
      this.filterMap.set(filter, matchedFilter);
    }
    this.filterMap.forEach(x => this.BuSpecificFilterArray.push(x));
  }
}
