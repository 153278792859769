<header></header>
<!-- fixed content on page-->
<div class="fixedContent">
  <div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="5px"
  class="filters"
  >
  <mat-form-field *ngFor="let filter of BuSpecificFilterArray"
      matTooltip="Filter wells by their {{filter.title}}"
      appearance="outline"
      class="basic">
      <mat-label class="filters-label">{{filter.title}}</mat-label>
      <mat-select [formControl]="filter.form" multiple>
        <mat-option
          *ngFor="let element of filter.options"
          [value]="element"
          (onSelectionChange)="applyFilter(filter.key, element.value, $event)"
        >
          {{ element.value }}
        </mat-option>
      </mat-select>

    </mat-form-field>

    <span class="spacer"></span>

    <button class="clear-button" (click)="resetFilters()" mat-raised-button>
      Clear All
    </button>
  </div>

  <hr />

  <mat-progress-bar
    *ngIf="isLoadingFilter"
    color="primary"
    mode="query"
  ></mat-progress-bar>

  <div class="container" fxLayoutGap="10px">
    <button
      class="issues-button"
      mat-raised-button
      matTooltip="There are {{
        issuesCount
      }} total issues. Note: An 'Issue' is defined as a well that has a deviation of +/- 3% from the Wellview Field Estimate or SAP costs."
      [matBadge]="issuesCount > 99 ? '99+' : issuesCount"
      matBadgePosition="before"
      matBadgeColor="warn"
      (click)="filterIssues()"
    >
      <mat-icon class="error-icon">error</mat-icon> Issues
    </button>
    <button
      class="issues-button"
      mat-raised-button
      matTooltip="There are {{
        nullCount
      }} total missing values. Note: 'Data quality' issue is defined as a well missing the WV Field Est. ​or AFE."
      [matBadge]="nullCount > 99 ? '99+' : nullCount"
      matBadgePosition="before"
      matBadgeColor="warn"
      (click)="filterNulls()"
    >
      <mat-icon class="null-icon">report_problem</mat-icon> Data Quality
    </button>
    <mat-form-field class="search-field" appearance="outline">
      <mat-label>Search in table</mat-label>
      <input
        matInput
        (keyup)="filterByValue($event.target.value)"
        (change)="filterByValue($event.target.value)"
        placeholder="Ex. Ballymore, 2020-03-22"
        [(ngModel)]="value"
        #input
      />
      <button
        class="clear-button"
        mat-button
        *ngIf="value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="value = ''; resetFilters()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button class="search-button" mat-raised-button color="primary">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>


<mat-toolbar class="table-toolbar mat-elevation-z6">
  <span class="table-title">wells</span>
  <button
    mat-button
    appearance="none"
    *ngIf="selection.selected.length >= 1"
    class="table-count"
  >
    {{ selection.selected.length }}
    {{ selection.selected.length == 1 ? "well" : "wells" }} selected<span
      class="material-icons"
    >
      keyboard_arrow_down
    </span>
  </button>
  <span class="spacer"></span>
  <div fxLayoutGap="10px" style="padding-top: 17px">
    <a
      mat-button
      matTooltip="Click to view Power BI Reports"
      target="_blank"
      href={{pbiReportUrl}}
      ><img src="assets/images/powerbi.png"
    /></a>
    <mat-form-field
      matTooltip="Click to view actions taken on selected wells"
      style="font-size: 14px; max-width: 150px"
      appearance="outline"
      *ngIf="selection.selected.length >= 1"
    >
      <mat-label>Well Actions</mat-label>
      <mat-select
        (selectionChange)="routeToCompareWells($event)"
        [(ngModel)]="selectedOption"
      >
        <mat-option value="compare-wells">Compare wells</mat-option>
        <mat-option value="cost-categories">Cost Categories</mat-option>
        <mat-option value="add-to-favorites">Add To Favorites</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="sort-button" appearance="outline">
      <mat-label>Sort Wells By</mat-label>
      <mat-select placeholder="Sort Wells By">
        <mat-option (click)="sortDataSource('jobEnd', 'desc')"
          value="Date(Recent)">Date(Recent)</mat-option
        >
        <mat-option (click)="sortDataSource('jobEnd', 'asc')"
          value ="Date(Oldest)">Date(Oldest)</mat-option
        >
        <mat-option (click)="sortDataSource('','')">Clear</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
        [color]="switchColor"
        [(ngModel)]="favoriteFlag"
        (change)="applyFilter(filterColumns.favorite, '', $event)">
      Show Favorites?
    </mat-slide-toggle>
    <button
      matTooltip="Click to see Data Refresh Date"
      mat-button
      (click)="openInfo()"
    >
      <span class="material-icons"> info </span>
    </button>
  </div>
</mat-toolbar>

<div class="table-container">
  <div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    multiTemplateDataRows
    matSort
  >
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th
        matTooltip="Check here to select/unselect all"
        mat-header-cell
        *matHeaderCellDef
      >
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          style="margin-right: 10px"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Well Name Column -->
    <ng-container matColumnDef="wellviewWellName">
      <th
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="border-right: 2px solid #dbdcdd"
      >
        Well Name
      </th>
      <td
        class="td"
        style="border-right: 2px solid #dbdcdd"
        mat-cell
        *matCellDef="let well"
      >
        {{ well.wellviewWellName }}
        <span
          [ngClass]="
            well.calculateSapDiffPercent >= -3 &&
            well.calculateSapDiffPercent <= 3
              ? 'hide'
              : 'show-issue'
          "
        >
          <mat-icon
            matTooltip="This well has costs that are ±3% from Wellview, please drill down to the cost-categories to take action"
            class="error-icon"
            >error</mat-icon
          >
        </span>
        <span
          [ngClass]="
            well.fieldEst === null || well.afeCost === null
              ? 'show-null'
              : 'hide'
          "
        >
          <mat-icon
            matTooltip="This well has a missing WV Field Estimate or AFE, please look into Wellview to diagnose the problem."
            class="null-icon"
            >report_problem</mat-icon
          >
        </span>
        <span
          [ngClass]="
            well.favorite == 1 || well.favorite == true
              ? 'show-favorite'
              : 'hide'
          "
        >
          <mat-icon
            matTooltip="favorite"
            class="favorite-icon"
            >bookmark</mat-icon
          >
        </span>
        <span
          [ngClass]="
            well.jobEnd == null || well.jobEnd == undefined
              ? 'show-flag'
              : 'hide'
          "
        >
          <mat-icon
            matTooltip="This well/job is either active or the job end date has not been put into WV"
            class="flag-icon"
            >flag</mat-icon
          >
        </span>
      </td>
    </ng-container>

    <!-- AFE Column -->
    <ng-container matColumnDef="afeNumber">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header>
        AFE
      </th>
      <td class="td" mat-cell *matCellDef="let well">{{ well.afeNumber }}</td>
    </ng-container>

    <!-- Primary Job Type Column -->
    <ng-container matColumnDef="primaryJobType">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header>
        Primary Job Type
      </th>
      <td class="td" mat-cell *matCellDef="let well">
        {{ well.primaryJobType }}
      </td>
    </ng-container>

    <!-- AFE ($) Column -->
    <ng-container matColumnDef="afeCost">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="AFE Dollar Amount gotten from WV">
        AFE($)
      </th>
      <td
        class="td"
        mat-cell
        *matCellDef="let well"
        [ngClass]="well.afeCost === null ? 'null-text' : ''"
      >
        {{
          well.afeCost | currency: "USD":"symbol":"1.0-0" | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- WV Field Est. ($) Column -->
    <ng-container matColumnDef="fieldEst">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="The amount recorded in WellView by Wells personnel. WV is usually the first step in the cost process for a given service or product">
        WV Field Est($)
      </th>
      <td
        class="td"
        mat-cell
        *matCellDef="let well"
        [ngClass]="well.fieldEst === null ? 'null-text' : ''"
      >
        {{
          well.fieldEst | currency: "USD":"symbol":"1.0-0" | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- SAP ($) Column -->
    <ng-container matColumnDef="sapCost">
      <th class="th" mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="The amount recorded in SAP based on invoices approved, and the final amount that has “hit the books”. Usually the last step in the cost process for a given service or product">
        SAP($)
      </th>
      <td
        class="td"
        mat-cell
        *matCellDef="let well"
        [ngClass]="well.sapCost === null ? 'null-text' : ''"
      >
        {{
          well.sapCost === null
            ? "N/A"
            : (well.sapCost | currency: "USD":"symbol":"1.0-0")
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./AFE($) Column -->
    <ng-container matColumnDef="calculateWellviewDiffCost">
      <th
        matTooltip="Wellview Field Est. vs AFE differrence in dollars"
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        WV Field Est - AFE($)
      </th>
      <td
        class="td"
        mat-cell
        *matCellDef="let well"
        [ngClass]="well.calculateWellviewDiffCost === null ? 'null-text' : ''"
      >
        {{
          well.calculateWellviewDiffCost
            | currency: "USD":"symbol":"1.0-0"
            | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./AFE(%) Column -->
    <ng-container matColumnDef="calculateWellviewDiffPercent">
      <th
        matTooltip="Wellview Field Est. vs AFE differrence in percent"
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        WV Field Est vs AFE(%)
      </th>
      <td
        class="td-2"
        mat-cell
        *matCellDef="let well"
        [ngClass]="
          well.calculateWellviewDiffPercent > 3 || well.calculateWellviewDiffPercent <= -3
            ? 'negative'
            : well.calculateWellviewDiffPercent === null
            ? 'null-text'
            : 'positive'
        "
      >
        {{
          well.calculateWellviewDiffPercent === null
            ? "N/A"
            : (well.calculateWellviewDiffPercent / 100 | percent: "1.2-2")
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./SAP($) Column -->
    <ng-container matColumnDef="calculateSapDiffCost">
      <th
        matTooltip="Wellview Field Est. vs SAP differrence in dollars"
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        WV Field Est - SAP($)
      </th>
      <td
        class="td"
        mat-cell
        *matCellDef="let well"
        [ngClass]="well.calculateSapDiffCost === null ? 'null-text' : ''"
      >
        {{
          well.calculateSapDiffCost
            | currency: "USD":"symbol":"1.0-0"
            | replaceNullWithText
        }}
      </td>
    </ng-container>

    <!-- WV Field Est./SAP(%) Column -->
    <ng-container matColumnDef="calculateSapDiffPercent">
      <th
        matTooltip="Wellview Field Est. vs SAP differrence in percent"
        class="th"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        WV Field Est vs SAP(%)
      </th>
      <td
        class="td-2"
        mat-cell
        *matCellDef="let well"
        [ngClass]="
          well.calculateSapDiffPercent > 3 || well.calculateSapDiffPercent <= -3
            ? 'negative'
            : well.calculateSapDiffPercent === null
            ? 'null-text'
            : 'positive'
        "
      >
        {{
          well.calculateSapDiffPercent === null
            ? "N/A"
            : (well.calculateSapDiffPercent / 100 | percent: "1.2-2")
        }}
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let well"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="cost-detail"
          [@detailExpand]="well == expandedCost ? 'expanded' : 'collapsed'"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="50px"
            class="cost-description"
          >
            <div class="cost-description-title-1">
              <span style="font-weight: bold">Well Summary</span>
              <div>
                <div class="cost-description-value-2">
                  This is a well summary for:
                </div>
                <div class="cost-description-value-well-name">
                  {{ well.wellviewWellName }}
                </div>
              </div>
            </div>
            <div class="cost-description-title" *ngFor="let detail of BuSpecificDetailColumns">
              {{detail.Title}}
              <div class="cost-description-value">
                {{ well[detail.IWellAttributeName] }}
              </div>
            </div>
            <div *ngIf="well.favorite == 1 || well.favorite == true" class="cost-description-title">
              <button class="clear-button" (click)="removeFavorite(well)" mat-raised-button>
                Remove Bookmark
              </button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr
      mat-row
      *matRowDef="let well; columns: displayedColumns"
      class="cost-row"
      [class.expanded-row]="expandedCost === well"
      (click)="expandRowClick(well)"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>
