import { Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SettingsTableModalComponent } from '../settings-table-modal/settings-table-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WellsService } from '../../services/wells.service';
import { ICostCategory, CostCategory, sapVendor, SelectedVendor, invoice, invoiceFilter, InvoiceFilterColumns, DataRefresh, GLDetails, costCategoryPageContainer } from '../../interfaces/cost-category';
import moment from 'moment';
import { DataInfoModalComponent } from '../data-info-modal/data-info-modal.component';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { BU } from 'src/app/interfaces/business-unit';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CalAngularService } from '@cvx/cal-angular';

@Component({
  selector: 'cost-categories',
  templateUrl: './cost-categories.component.html',
  styleUrls: ['./cost-categories.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CostCategoriesComponent implements OnInit {
  panelOpenState = false;
  categorySearchValue = '';
  invoiceSearchValue = '';
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['select', 'wellName', 'afeNumber', 'codeDescription', 'primaryJobType', 'afeCost', 'fieldEst', 'invoiceTotal', 'sapCost', 'calculateWellviewDiffCost', 'calculateWellviewDiffPercent', 'calculateSapDiffCost', 'calculateSapDiffPercent'];
  dataSource: MatTableDataSource<CostCategory>;
  dataRefreshInfo: DataRefresh[] = [];
  selection = new SelectionModel<ICostCategory>(true, []);
  vendorSelection = new SelectionModel<sapVendor>(true, []);
  expandedCost: CostCategory;
  selectedVendors: sapVendor[] = [];
  invoices: invoice[];
  filteredInvoices: invoice[];
  isLoading = true;
  invoiceFilters: invoiceFilter = new invoiceFilter();
  isLoadingFilter = false;
  filterValues = {};
  filterColumns = InvoiceFilterColumns;
  invoiceRange;
  pbiReportUrl: string;
  mgcFlag: boolean = false;
  mgcToggleFlag: boolean = false;
  switchColor: ThemePalette = 'primary';
  ShowMGCDetials: boolean = false;
  showPendingInvoices: boolean = true;
  showApprovedInvoices: boolean = true;
  invoiceStatusFilter = new UntypedFormControl();
  statusFilterOptions = ["pending", "approved"];
  selectedBU: BU;
  minInvoiceDate: string = "";
  maxInvoiceDate: string = "";
  invoiceTotalHeader: string = "";
  invoiceTotalTooltip: string = "";

  constructor(
    public dialog: MatDialog, 
    public wellService: WellsService, 
    public buService: BusinessUnitService, 
    private datePipe: DatePipe, 
    private myMonitoringService: MyMonitoringService,
    private authService: CalAngularService) {
    this.invoiceStatusFilter.setValue([this.statusFilterOptions[0], this.statusFilterOptions[1]]);
  }

  openSettings() {
    this.dialog.open(SettingsTableModalComponent, {
      width: '860px',
      height: '300px'
    });
  }

  openInfo() {
    let refreshDataMapped: { Datasource: string; LastRefresh: string }[] = [];
    this.dataRefreshInfo.forEach(item => {
      let element = { Datasource: item.Datasource, LastRefresh: this.datePipe.transform(item.LastRefresh, 'yyyy-MM-dd') };
      refreshDataMapped.push(element);
    })
    this.dialog.open(DataInfoModalComponent, {
      width: '860px',
      height: '300px',
      data: { dataRefresh: refreshDataMapped }
    })
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatSelect) select: QueryList<MatSelect>;

  ngOnInit() {
    this.selectedBU = this.buService.getSelectedBU();
    this.pbiReportUrl = this.buService.getPowerBILink();
    this.invoiceTotalHeader = this.buService.getInvoiceTotalColumnHeader();
    this.invoiceTotalTooltip = this.buService.getInvoiceTotalColumnTooltip();
    this.isLoadingFilter = false;
    this.dataRefreshInfo = this.wellService.dataRefresh;
    this.logTelemtry();
    this.wellService.getInvoiceFilters()
      .subscribe((x: invoiceFilter) => {
        this.invoiceFilters = x;
      });
    this.wellService.getCostCategories()
      .subscribe((y: costCategoryPageContainer) => {
        this.dataSource = new MatTableDataSource(this.wellService.costCategoryList);
        this.checkForMGCCodes();
        //console.log("this.dataSource=>",y);
        this.dataSource.filterPredicate = this.createFilter();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.expandedCost = this.wellService.costCategoryList[0];
        this.selectedVendors = this.wellService.costCategoryList[0].glDetails[0] != undefined
          ? this.wellService.costCategoryList[0].glDetails[0].invoiceVendors
          : [];
        this.wellService.selectedVendors = [new SelectedVendor()];
        // tslint:disable-next-line: max-line-length
        if (this.wellService.costCategoryList && this.wellService.costCategoryList.length > 0
          && this.wellService.costCategoryList[0].glDetails[0]
          && this.wellService.costCategoryList[0].glDetails[0].invoiceVendors
          && this.wellService.costCategoryList[0].glDetails[0].invoiceVendors.length > 0) {
            this.updateSelectedVendors(this.wellService.costCategoryList[0].glDetails[0].invoiceVendors[0]);
            this.vendorSelection.select(this.wellService.costCategoryList[0].glDetails[0].invoiceVendors[0]);
        }
      });
  }

  logTelemtry() {
    this.myMonitoringService.logPageView("Cost_Categories_Page");
    for(let well of this.wellService.selectedWells)
    {
      let properties: {[k: string]: any} = {};
      properties.WellID = well.wellId;
      properties.User = this.authService.cvxClaimsPrincipal.email;
      properties.BU = BU[this.selectedBU];
      properties.jobTitle = this.authService.cvxClaimsPrincipal.jobTitle;
      this.myMonitoringService.logEvent("Cost_Categories_Open_Event", properties);
    }
  }

  checkForMGCCodes() {
    let anyMGCCodes: boolean = this.dataSource.data.length > 0 ? this.dataSource.data.map(x => x.MixCategoryFlag).reduce((acc, value) => acc || value) : false;
    this.mgcToggleFlag = this.dataSource.data.length > 0 ? this.dataSource.data.map(x => x.glDetails.length > 1).reduce((acc, value) => acc || value): false; // Only show Toggle if there are more than 1 mapped MGC
    if(anyMGCCodes)
    {
      this.displayedColumns.splice(6, 0, "MGCCategories");
      this.mgcFlag = true;
    }

  }

  applyFilter(column, filterValue: string, event: any ) {
    switch(column){
      case 'vendor':
      case 'codeDescription':
      case 'costCategory':
        break;
      case 'invoiceReceived':
        this.minInvoiceDate = event.start != undefined ? event.start.format() : "";
        this.maxInvoiceDate = event.end != undefined ? event.end.format() : "";
        break;
      case 'invoiceStatus':
        if(filterValue == 'pending')
        {
          this.showPendingInvoices = event.source.selected;
        }
        else if(filterValue == 'approved')
        {
          this.showApprovedInvoices = event.source.selected;
        }

        break;
    }
    if(this.selectedVendors)
    {
      let dateFilteredInvoices = this.filterInvoiceByDate(this.invoices, this.minInvoiceDate, this.maxInvoiceDate);
      this.filteredInvoices = this.filterInvoiceByStatus(dateFilteredInvoices);
    }
    this.setAndRemoveValues(column, filterValue, event);
    if(this.dataSource){
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }
  }

  setAndRemoveValues(column, filterValue, event) {
    if((event.source && event.source.selected) && filterValue)
    {
      this.filterValues[column] = (this.filterValues[column] != ''&& this.filterValues[column] !=null && this.filterValues[column] !=undefined)? (this.filterValues[column] +','+ filterValue.trim().toLowerCase()): filterValue.trim().toLowerCase(); // Remove whitespace
    }
    else if(event.end && event.start)
    {
      this.filterValues["InvoiceReceived"] = moment(event.start, 'YYYY-MM-DD').format() + ", " + moment(event.end, 'YYYY-MM-DD').format();
    }
    else {
      this.filterValues[column] = this.removeValue(this.filterValues[column], filterValue, ',');
      if(!this.filterValues[column]) {
        delete this.filterValues[column];
      }
    }
 }

  removeValue(list, value, separator) {
    separator = separator || ",";
    if (list) {
      var values = list.split(separator);
      for (var i = 0; i < values.length; i++) {
        if (values[i].trim().toLowerCase() == value.trim().toLowerCase()) {
          values.splice(i, 1);
          return values.join(separator);
        }
      }
    }
    return list;
  }

  filterByValue(searchTerm) {
    var clonedWells = this.dataSource.filteredData;
    var filteredWells = clonedWells.filter((data) => JSON.stringify(data).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
    this.dataSource = new MatTableDataSource(filteredWells);
    this.dataSource.filterPredicate = this.createFilter();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoadingFilter = false
  }

  // Apply filters from the header first, then apply search term filter
  filterInvoiceByValue(searchTerm:string) {
    //Filters from header
    this.filteredInvoices = this.filterInvoiceByStatus(this.invoices);
    this.filteredInvoices = this.filterInvoiceByDate(this.filteredInvoices, this.minInvoiceDate, this.maxInvoiceDate);

    //Filter by search term
    searchTerm = searchTerm.toLowerCase();
    this.filteredInvoices = this.filteredInvoices.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm));
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col]) {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      let nameSearch = () => {
        if (isFilterSet) {
          let multipleFilterFind: boolean[] = [];
          for (const col in searchTerms) {
            let found = false;
            // Special vendor search to search in both sap and wv vendors
            if(col == "vendor")
            {
              searchTerms[col].trim().toLowerCase().split(',').forEach(word => {
                let vendors: string[] = [];
                data.glDetails.forEach(detail => {
                  detail.sapVendors.map(x => x.vendor).forEach(vendorName => {
                    if(vendorName!=null)
                      vendors.push(vendorName);
                  });
                  detail.wvVendors.map(x => x.vendor_description).forEach(vendorName => {
                    if(vendorName!=null)
                      vendors.push(vendorName);
                  });
                });
                vendors.forEach(vendor => {
                  if(word.toLowerCase().includes(vendor.toLowerCase()))  //Have to use includes bc some of the vendor data isn't 1 to 1, DQ Issue
                    found = true;
                })
              });
            }
            else if(col == "invoiceStatus")
            {
              let approvedPending: string[] = searchTerms[col].trim().toLowerCase().split(',');
              let showApproved = approvedPending.includes("approved");
              let showPending = approvedPending.includes("pending");
              if(showApproved && showPending)
                found = true;
              else if(showApproved)
                found = data.hasApprovedInvoices;
              else if(showPending)
                found = data.hasPendingInvoices;
            }
            else if(col == "InvoiceReceived")
            {
              let minMaxDates: string[] = searchTerms[col].split(',');
              let minReceivedDate = minMaxDates[0].trim();
              let maxReceivedDate = minMaxDates[1].trim();
              if(moment(data.MaxDateInvoiceReceived).diff(moment(minReceivedDate)) < 0)
              {
                found = false;
              }
              else if(moment(data.MinDateInvoiceReceived).diff(moment(maxReceivedDate)) > 0)
              {
                found = false;
              }
              else
              {
                found = true;
              }

            }
            //Normal search requirements
            else
            {
              searchTerms[col].trim().toLowerCase().split(',').forEach(word => {
              if (data[col] && data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
            }
            multipleFilterFind.push(found);

          }
          return multipleFilterFind.reduce((acc,curValue) => acc && curValue); //Returns true only if all filters enabled return true
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  resetFilters() {
    this.isLoadingFilter = true;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.wellService.costCategoryList);
      this.filterValues = {};
      this.select.toArray().forEach((s: MatSelect) => s.options.forEach((item: MatOption) => item.deselect()));
      this.dataSource.filterPredicate = this.createFilter();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoadingFilter = false;
      this.invoiceRange = null;
      this.categorySearchValue = '';
    }, 1)
  }

  // Reset any search term filters, but still apply global filters like InvoiceStatus and Invoice Received Date
  resetInvoiceFilters() {
    this.filteredInvoices = this.filterInvoiceByStatus(this.invoices);
    this.filteredInvoices = this.filterInvoiceByDate(this.filteredInvoices, this.minInvoiceDate, this.maxInvoiceDate)
    this.invoiceSearchValue = '';
  }

  sortDataSource(id: string, start: string) {
    this.dataSource.sort.sort(<MatSortable>({ id: id, start: start }));
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: ICostCategory): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.wellName + 1}`;
  }

  isAllSelected() {
    const numSelected = this.selection == null || this.selection.selected == null ? 0 : this.selection.selected.length;
    const numRows = this.dataSource == null || this.dataSource.filteredData == null ? 0 : this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  expandRowClick(costElement: CostCategory) {
    this.expandedCost = this.expandedCost === costElement ? null : costElement;
    this.selectedVendors = costElement.glDetails.length > 0 ? costElement.glDetails[0].invoiceVendors : [new sapVendor()];
    if (costElement.glDetails[0] && costElement.glDetails[0].invoiceVendors && costElement.glDetails[0].invoiceVendors.length > 0) {
      this.updateSelectedVendors(costElement.glDetails[0].invoiceVendors[0]);
    }
  }

  updateSelectedVendors(vendor: sapVendor) {
    this.vendorSelection.toggle(vendor);
    this.selectedVendors = this.vendorSelection.selected;
    this.wellService.selectedVendors = [];
    for(let x = 0; x < this.selectedVendors.length; x++)
    {
      let selectedVendor = this.selectedVendors[x];
      let wellServiceVendorModel = new SelectedVendor();
      wellServiceVendorModel.SupplierID = selectedVendor.supplierId;
      wellServiceVendorModel.description = selectedVendor.description;
      wellServiceVendorModel.wellID = this.expandedCost.wellId;
      wellServiceVendorModel.afeNumber = this.expandedCost.afeNumber;
      wellServiceVendorModel.jobType = selectedVendor.jobType;
      this.wellService.selectedVendors.push(wellServiceVendorModel);
    }

    this.isLoading = true;
    this.wellService.getInvoices()
      .subscribe((i: invoice[]) => {
        this.invoices = i;
        this.filteredInvoices = this.filterInvoiceByStatus(i);
        this.filteredInvoices = this.filterInvoiceByDate(this.filteredInvoices, this.minInvoiceDate, this.maxInvoiceDate)
        this.isLoading = false;

      });
  }

  getTotalWVVendor(mgcIndex: number) {
    if(!this.expandedCost || this.expandedCost.glDetails[mgcIndex] === undefined)
    {
      return 0;
    }
    let wvVendors = this.expandedCost.glDetails[mgcIndex].wellviewVendors;
    if (wvVendors && wvVendors.length > 0) {
      return wvVendors.map(t => +t.cost).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalSapVendor(mgcIndex: number) {
    if (!this.expandedCost || this.expandedCost.glDetails[mgcIndex] === undefined)
    {
      return 0;
    }

    let sapVendors = this.expandedCost.glDetails[mgcIndex].sapVendors;
    if (sapVendors && sapVendors.length > 0) {
      return sapVendors.map(t => +t.calculatedSap).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalInvoiceVendor(mgcIndex: number) {
    if (!this.expandedCost || this.expandedCost.glDetails[mgcIndex] === undefined)
    {
      return 0;
    }

    let invoiceVendors = this.expandedCost.glDetails[mgcIndex].invoiceVendors;
    if (invoiceVendors && invoiceVendors.length > 0) {
      return invoiceVendors.map(t => +t.calculatedSap).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalGrandInvoice() {
    if (this.invoices && this.invoices.length > 0) {
      return this.invoices.map(t => +t.discountAmount).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalSapInvoice() {
    if ( this.invoices && this.invoices.length > 0) {
      return this.invoices.filter(f => f.statusString == 'Approved').map(t => +t.discountAmount).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalAribaInvoice() {
    if ( this.invoices && this.invoices.length > 0) {
      return this.invoices.filter(f => f.statusString == 'Pending').map(t => +t.discountAmount).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'wellName': return compare(a.wellName, b.wellName, isAsc);
        case 'afe': return compare(a.afeNumber, b.afeNumber, isAsc);
        case 'codeDescription': return compare(a.codeDescription, b.codeDescription, isAsc);
        case 'primaryJobType': return compare(a.primaryJobType, b.primaryJobType, isAsc);
        case 'afeCost': return compare(a.afeCost, b.afeCost, isAsc);
        case 'fieldEst': return compare(a.glDetails[0].fieldEst, b.glDetails[0].fieldEst, isAsc);
        case 'sapCost': return compare(a.glDetails[0].sapCost, b.glDetails[0].sapCost, isAsc);
        case 'calculateWellviewDiffCost': return compare(a.calculateWellviewDiffCost, b.calculateWellviewDiffCost, isAsc);
        case 'calculateWellviewDiffPercent': return compare(a.calculateWellviewDiffPercent, b.calculateWellviewDiffCost, isAsc);
        case 'calculateSapDiffCost': return compare(a.glDetails[0].calculateSapDiffCost, b.glDetails[0].calculateSapDiffCost, isAsc);
        case 'calculateSapDiffPercent': return compare(a.glDetails[0].calculateSapDiffPercent, b.glDetails[0].calculateSapDiffPercent, isAsc);
        default: return 0;
      }
    });
  }

  determineColorClass(data: CostCategory, field: string): string
  {
    let cssClass = '';
    switch(true) {
      case data.glDetails.length == 0:
        cssClass = 'null-text'
        break;
      case data.glDetails.length > 1:
        cssClass = '';
        break;
      case data.glDetails[0][field] === null:
        cssClass = 'null-text';
        break;
      case (data.glDetails[0][field] > 3 || data.glDetails[0][field] <= -3):
        cssClass = 'negative';
        break;
      default:
        cssClass = 'positive';
    }
    return cssClass;

  }

  determineMGCColorClass(data: GLDetails, field: string): string
  {
    let cssClass = '';
    switch(true) {
      case data[field] === null:
        cssClass = 'null-text'
        break;
      case (data[field] > 3 || data[field] <= -3):
        cssClass = 'negative';
        break;
      default:
        cssClass = 'positive';
    }
    return cssClass;

  }

  filterInvoiceByStatus(invoices: invoice[]): invoice[]
  {
    if(this.showApprovedInvoices && this.showPendingInvoices)
    {
      return invoices;
    }
    else if(this.showApprovedInvoices)
    {
      return invoices.filter(x => x.statusString.toLowerCase() == 'approved');
    }
    else if(this.showPendingInvoices)
    {
      return invoices.filter(x => x.statusString.toLowerCase() == 'pending')
    }
    else
    {
      return invoices;
    }
  }

  filterInvoiceByDate(invoices: invoice[], minDate:string, maxDate:string): invoice[]
  {
    if(minDate == "" || maxDate == "")
      return invoices;
    return invoices.filter(x => moment(minDate).diff(moment(x.invoiceReceived)) <= 0 && moment(maxDate).diff(moment(x.invoiceReceived)) >= 0 );
  }

  sortInvoices(sort: Sort) {
    const data = this.filteredInvoices;
    if (!sort.active || sort.direction === '') {
      this.filteredInvoices = data;
      return;
    }

    this.filteredInvoices = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'aribaDocID': return compare(a.aribaDocId, b.aribaDocId, isAsc);
        case 'contractID': return compare(a.contractId, b.contractId, isAsc);
        case 'invoiceReceived': return compare(a.invoiceReceived, b.invoiceReceived, isAsc);
        case 'invoiceApproved': return compare(a.approvedDate, b.approvedDate, isAsc);
        case 'serviceDate': return compare(a.EndDate, b.EndDate, isAsc);
        case 'status': return compare(a.statusString, b.statusString, isAsc);
        case 'description': return compare(a.description, b.description, isAsc);
        case 'sapDescription': return compare(a.sapDescription, b.sapDescription, isAsc);
        case 'itemNumber': return compare(a.partKey, b.partKey, isAsc);
        case 'unitOfMeasure': return compare(a.unitMeasure, b.unitMeasure, isAsc);
        case 'unitPrice': return compare(a.unitPrice, b.unitPrice, isAsc);
        case 'quantity': return compare(parseInt(a.Quantity, 10), parseInt(b.Quantity, 10), isAsc);
        case 'amountAfterDiscount': return compare(parseInt(a.discountAmount, 10), parseInt(b.discountAmount, 10), isAsc);
        case 'DSO': return compare(parseInt(a.DSO, 10), parseInt(b.DSO, 10), isAsc);
        case 'invoiceDocument': return compare(a.aribaLink, b.aribaLink, isAsc);
        case 'SupplierID': return compare(a.SupplierID, b.SupplierID, isAsc);
        default: return 0;
      }
    });
  }

  getSelectedVendorNames()
  {
    let result = "";
    for(let i = 0; i < this.selectedVendors.length; i++)
    {
      result += (this.selectedVendors[i].vendor + ", ");
    }
    result = result.slice(0, result.length-2); //Get rid of trailing ", "
    return result;
  }


}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
