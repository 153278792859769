import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { environment } from "src/environments/environment";
import { BU } from "../interfaces/business-unit";
import { FilterColumns, WellDetailsColumn } from "../interfaces/cost-category";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {
  selectedBU = BU.GOMBU;
  initialized = false;

  constructor(private authService: CalAngularService) {
      
  }

  getSelectedBU(): BU {
    return this.selectedBU;
  }

  setSelectedBU(buName: string) {
      this.selectedBU = BU[buName];
  }

  async initializeSelectedBU() {
      let gombuGroup = environment.AADGroupNames.find(x => x.BU == BU.GOMBU);
      let mcbuGroup = environment.AADGroupNames.find(x => x.BU == BU.MCBU);
      let rbuGroup = environment.AADGroupNames.find(x => x.BU == BU.RBU);
      let cemrecGroup = environment.AADGroupNames.find(x => x.BU == BU.CEMREC);
      let labuGroup = environment.AADGroupNames.find(x => x.BU == BU.LABU);

      let gomAccess = await this.authService.isInGroup(gombuGroup.GroupName);
      let mcbuAccess = await this.authService.isInGroup(mcbuGroup.GroupName);
      let rbuAccess = await this.authService.isInGroup(rbuGroup.GroupName);
      let cemrecAccess = await this.authService.isInGroup(cemrecGroup.GroupName);
      let labuAccess = await this.authService.isInGroup(labuGroup.GroupName);

      // Set selectedBU based on AAD groups with preference to GOMBU
      this.selectedBU = gomAccess ? gombuGroup.BU : 
        (mcbuAccess ? mcbuGroup.BU : 
          rbuAccess ? rbuGroup.BU :
          cemrecAccess ? cemrecGroup.BU:
          labuAccess ? labuGroup.BU:
          BU.NULL);
      
      this.initialized = true;

  }

  attachBUParamtertoApiURL(apiURL: string): string
  {
    let bu: string;
    switch(this.selectedBU)
    {
      case 0:
        bu = "GOM";
        break;
      case 1:
        bu = "MCBU";
        break;
      case 2:
        bu = "RBU";
        break;
      case 3:
        bu = "CEMREC";
        break;
      case 4:
        bu = "LABU";
        break;
      default:
        bu = "";
        break;
    }
    return `${apiURL}&businessUnit=${bu}`;
  }

  getWellFilters(): FilterColumns[]
  {
    let filterColumns: FilterColumns[] = [];
    if(this.selectedBU == BU.GOMBU)
    {
      filterColumns.push(FilterColumns.area, FilterColumns.fieldName, FilterColumns.sapWellName, FilterColumns.rigName, FilterColumns.primaryJobType, FilterColumns.jobEndYear, FilterColumns.formation );
    }
    else if(this.selectedBU == BU.MCBU)
    {
      filterColumns.push(FilterColumns.devArea, FilterColumns.padName,  FilterColumns.sapWellName, FilterColumns.rigName, FilterColumns.primaryJobType, FilterColumns.jobEndYear, FilterColumns.formation);
    }
    else if(this.selectedBU == BU.RBU)
    {
      filterColumns.push(FilterColumns.devArea, FilterColumns.padName,  FilterColumns.sapWellName, FilterColumns.rigName, FilterColumns.primaryJobType, FilterColumns.jobEndYear, FilterColumns.formation);
    }
    else if(this.selectedBU == BU.CEMREC)
    {
      filterColumns.push(FilterColumns.devArea, FilterColumns.padName,  FilterColumns.sapWellName, FilterColumns.rigName, FilterColumns.primaryJobType, FilterColumns.jobEndYear, FilterColumns.formation);
    }
    else if(this.selectedBU == BU.LABU)
    {
      filterColumns.push(FilterColumns.devArea, FilterColumns.padName,  FilterColumns.sapWellName, FilterColumns.rigName, FilterColumns.primaryJobType, FilterColumns.jobEndYear, FilterColumns.formation);
    }
    return filterColumns;
  }

  getWellDetailColumns(): WellDetailsColumn[]
  {
    let detailColumns: WellDetailsColumn[] = [];
    if(this.selectedBU == BU.GOMBU)
    {
      detailColumns.push(new WellDetailsColumn("Operated", "operator"), new WellDetailsColumn("Area", "area"), new WellDetailsColumn("Well Name", "sapWellName"));
      detailColumns.push(new WellDetailsColumn("Rig Name", "rigName"), new WellDetailsColumn("Job Type", "jobType"), new WellDetailsColumn("Job End", "jobEnd"));
      detailColumns.push(new WellDetailsColumn("Formation", "formation"), new WellDetailsColumn("Job Category", "jobCategory"));
    }
    else if(this.selectedBU == BU.MCBU)
    {
      detailColumns.push(new WellDetailsColumn("Operated", "operator"), new WellDetailsColumn("Dev Area", "devAreaCode"), new WellDetailsColumn("Pad Name", "padName"));
      detailColumns.push(new WellDetailsColumn("Well Name", "sapWellName"), new WellDetailsColumn("Rig Name", "rigName"), new WellDetailsColumn("Job Type", "jobType"));
      detailColumns.push(new WellDetailsColumn("Job End", "jobEnd"), new WellDetailsColumn("Job Category", "jobCategory"), new WellDetailsColumn("API Number", "APINumber"));
    }
    else if(this.selectedBU == BU.RBU)
    {
      detailColumns.push(new WellDetailsColumn("Operated", "operator"), new WellDetailsColumn("Dev Area", "devAreaCode"), new WellDetailsColumn("Pad Name", "padName"));
      detailColumns.push(new WellDetailsColumn("Well Name", "sapWellName"), new WellDetailsColumn("Rig Name", "rigName"), new WellDetailsColumn("Job Type", "jobType"));
      detailColumns.push(new WellDetailsColumn("Job End", "jobEnd"), new WellDetailsColumn("Job Category", "jobCategory"), new WellDetailsColumn("API Number", "APINumber"));
    }
    else if(this.selectedBU == BU.CEMREC)
    {
      detailColumns.push(new WellDetailsColumn("Operated", "operator"), new WellDetailsColumn("Dev Area", "devAreaCode"), new WellDetailsColumn("Pad Name", "padName"));
      detailColumns.push(new WellDetailsColumn("Well Name", "sapWellName"), new WellDetailsColumn("Rig Name", "rigName"), new WellDetailsColumn("Job Type", "jobType"));
      detailColumns.push(new WellDetailsColumn("Job End", "jobEnd"), new WellDetailsColumn("Job Category", "jobCategory"), new WellDetailsColumn("API Number", "APINumber"));
    }
    else if(this.selectedBU == BU.LABU)
    {
      detailColumns.push(new WellDetailsColumn("Operated", "operator"), new WellDetailsColumn("Dev Area", "devAreaCode"), new WellDetailsColumn("Pad Name", "padName"));
      detailColumns.push(new WellDetailsColumn("Well Name", "sapWellName"), new WellDetailsColumn("Rig Name", "rigName"), new WellDetailsColumn("Job Type", "jobType"));
      detailColumns.push(new WellDetailsColumn("Job End", "jobEnd"), new WellDetailsColumn("Job Category", "jobCategory"), new WellDetailsColumn("API Number", "APINumber"));
    }
    return detailColumns;
  }

  getPowerBILink(): string
  {
    let pbiLink = "";
    if(this.selectedBU==BU.GOMBU)
    {
       pbiLink = environment.pbiReportGOM;
    }
    else if(this.selectedBU==BU.MCBU)
    {
      pbiLink = environment.pbiReportMCBU;
    }
    else if(this.selectedBU==BU.RBU)
    {
      pbiLink = environment.pbiReportRBU;
    }
    else if(this.selectedBU==BU.CEMREC)
    {
      pbiLink = environment.pbiReportCEMREC;
    }
    else if(this.selectedBU==BU.LABU)
    {
      pbiLink = environment.pbiReportLABU;
    }
    return pbiLink;
  }

  getInvoiceTotalColumnHeader(): string
  {
    let header = "";
    if(this.selectedBU == BU.GOMBU)
    {
      header = "GEP/Ariba Invoice"
    }
    else if(this.selectedBU == BU.MCBU)
    {
      header = "GEP Invoice"
    }
    return header;
  }

  getInvoiceTotalColumnTooltip(): string
  {
    let tooltip = "";
    if(this.selectedBU == BU.GOMBU)
    {
      tooltip = "The amount recorded in GEP or Ariba based on invoices submitted. Includes pending and approved amounts. Usually the second step in the cost process for a given service or product."
    }
    else if(this.selectedBU == BU.MCBU)
    {
      tooltip = "The amount recorded in GEP based on invoices submitted. Includes pending and approved amounts. Usually the second step in the cost process for a given service or product."
    }
    return tooltip;
  }
}